@import "../styles/bulma.scss";
@import "../styles/cookieconsent.min.css";
@import "../styles/animate.css";
@import "../styles/type.scss";
@import "../styles/nav.scss";
@import "../styles/layout.scss";
@import "../styles/hero.scss";
@import "../styles/anim.scss";
@import "../styles/spacing.scss";

