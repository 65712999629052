.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background:#fff;
  opacity: 1;
  left:0;
  bottom: 0;
  transform: scale(0);
  transform-origin: bottom;
  z-index: 1000;
}

.transition-fade {
  opacity: 1;
  transition: .8s;
}

html.is-animating .transition-fade{
  opacity: 0;
}

.transition-wipe {
  transition: .7s cubic-bezier(0,0,.58,1);
  transform: scaleY(0);
}

html.is-animating .transition-wipe {
  transform: scaleY(1);
}

html.is-leaving .transition-fade {
  transform: translateY(-50vh);
  transition: 1s;
}

.best-in-class {
  position: relative;
}

.img_overlay {
  overflow: hidden;
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transform: scale(1.01);
  -ms-transition: opacity 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94), transform 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -webkit-transition: opacity 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94), transform 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: opacity 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94), transform 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);

}


.best-in-class:hover .img_overlay {
  opacity: 1;
  transform: scale(1);
}