body {
  font-family: 'aktiv-grotesk', sans-serif;
  font-weight: 400;
  font-style: normal;
  color: black !important;
  font-size: 18px;
}

h1, h2 {
  font-family: 'Plantin MT Pro';
  font-weight: 600;
}

p {
  margin-bottom: 20px;
}

.is-medium {
  font-weight: 500;
}

a {
  text-decoration: underline;
}

b {
  font-weight: 500;
}

@media only screen and (max-width: 687px) {
  .is-size-2 {
    font-size: 1.7rem !important;
  }
  .is-size-3 {
    font-size: 1.7rem !important;
  }
}