footer {
  margin-top: 100px;
  margin-bottom: 40px;
}
#mapDiv
{
    filter: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="g"><feColorMatrix type="matrix" values="0.3 0.3 0.3 0 0 0.3 0.3 0.3 0 0 0.3 0.3 0.3 0 0 0 0 0 1 0"/></filter></svg>#g');
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);    
    filter: progid:DXImageTransform.Microsoft.BasicImage(grayScale=1);
}

.accordion {
  display: none;
}

@media only screen and (max-width: 687px) {
  #tabs {
    display: none;
  }
  
  .accordion {
    display: block;
  }
  .navbar {
    padding-left: 15px;
    padding-right: 15px;
  }

  .container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .container.p-xl {
    padding: 2rem;
  }
}

.accordion-drawer-title {
  cursor: pointer;
  border-bottom: 1px solid #d7d7d7;
  font-weight: 500;
}

.accordion-drawer-title::after {
  content: '\2795'; 
  font-size: 13px;
  color: #777;
  float: right;
  margin-left: 5px;
}

.accordion-drawer-title.active::after {
  content: "\2796"; 
}

.accordion-drawer-content {
  display: none;
}

.accordion-drawer-content.is-open {
  display: block;
}

