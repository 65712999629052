a {
  color: black;
}

body {
  padding: 0;
  margin: 0;
}

.navbar {
  display: flex;
  flex: 1;
  vertical-align: middle;
  height: 100px;
  align-items: center;
  background-color: #fff;
  padding-left: 65px;
  padding-right: 65px;
  width: 100%;
  justify-content: space-between;
}

.container.is-fluid{margin-left:64px;margin-right:64px;max-width:none}

.mx-auto {
  margin: 0 auto;
}

.navbar a {
  text-decoration: none;
  color: black;
}

.tabsList li a{
  text-decoration: none;
}

.current {
  font-weight: 500;
}

.current::after {
  content: " > "
}

.overlay-nav {
  width: 100%;
  height: 100vh;
  top: -100vh;
  left: 0;
  opacity: 0;
  position: fixed;
  background-image: url(../img/ibc_fiduciaria_navigation_bg.jpg);
  background-size: cover;
  z-index: 1000;
  transition: .3s ease-in-out;
}

.nav-overlay-container {
  height: 100vh;
}

.overlay-nav a {
  color: #fff;
  text-decoration: none;
}

main {
  transition: .6s ease-in-out;
}

.align-self-center {
  align-self: center;
}

nav .overlay-nav-toggle {
  justify-self: flex-end;
}


.mx-auto {
  margin: 0 auto;
}

.is-cent {
  align-self: center;
}

.accordion-drawer {
  margin-bottom: 10px;
}

.container.is-fluid{
  margin-left:64px;
  margin-right:64px;
  max-width:none;
}

@media only screen and (max-width: 768px){
  .container.is-fluid{
    margin-left:0;
    margin-right:0;
    max-width:none;
  }
}